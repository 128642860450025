import { signature } from "@/core/api/useFetch";

const axios = require("axios").default;

const Api = {};
const headers = {
  "Content-Type": "application/json",
  "x-apitoken": process.env.NEXT_PUBLIC_API_TOKEN,
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
}

Api.headers = async (url = '') => {
  const data = await signature(url);

  const result = {
    ...headers,
    'x-signature': data?.['x-signature'],
    'x-timestamp': data?.['x-timestamp'],
  }

  return result;
}

Api.AXIOS = axios.create({
  headers: headers,
});

Api.ABOUT_IDEAL = (GroupName) =>
  `/api-dev/contents?type=pages&groupNames=${GroupName}&limit=10&page=1`;

Api.ADD_PRODUCT = (uid) =>
  `/api-dev/carts/${uid}/add-product`;

Api.ALGOLIA_DEVELOPERS = () =>
  `/api-dev/algolia-search/developers`;

Api.ALGOLIA_PROPERTIES = () =>
  `/api-dev/algolia-search/properties`;

Api.ALGOLIA_VILLAGES = () =>
  `/api-dev/algolia-search/villages`;

Api.APPLICATION = (uid) =>
  `/api-dev/Application/${uid}`;

Api.APPLICATION_MINIFIED = (uid) =>
  `/api-dev/Application/${uid}/short-detail`;

Api.APPLICATION_PROGRESS = (uid) =>
  `/api-dev/Application/${uid}/progress`;

Api.APPLICATION_FILE_ADD = (uid) =>
  `/api-dev/Application/${uid}/file`;

Api.APPLICATION_MULTI_FILE_ADD = (uid) =>
  `/api-dev/Application/${uid}/files`;

Api.APPLICATION_FILE_REMOVE = (fileUid) =>
  `/api-dev/Application/file/${fileUid}`;

Api.APPLICATION_FILES_REMOVE = (applicationUid, documentTypeUid) =>
  `/api-dev/Application/${applicationUid}/files/document-type/${documentTypeUid}`;

Api.APPLICATION_EMPLOYEE_INFO = (uid) =>
  `/api-dev/Application/${uid}/employee-info`;

Api.APPLICATION_ENTREPRENEUR_INFO = (uid) =>
  `/api-dev/Application/${uid}/entrepreneur-info`;

Api.APPLICATION_FINANCIAL_INFO = (uid) =>
  `/api-dev/Application/${uid}/financial-info`;

Api.APPLICATION_PERSONAL_INFO = (uid) =>
  `/api-dev/Application/${uid}/personal-info`;

Api.APPLICATION_PRODUCT_FINISH = () =>
  `/api-dev/Application/products/finish`;

Api.APPLICATION_PRODUCT_REJECT = (uid) =>
  `/api-dev/Application/products/reject/${uid}`;

Api.APPLICATION_PROFESSIONAL_INFO = (uid) =>
  `/api-dev/Application/${uid}/professional-info`;

Api.APPLICATION_ENTREPRENEUR_INFO = (uid) =>
  `/api-dev/Application/${uid}/entrepreneur-info`;

Api.APPLICATION_RELATIVE_INFO = (uid) =>
  `/api-dev/Application/${uid}/relative-info`;

Api.APPLICATION_REVISION_REMARK = (uid) =>
  `/api-dev/Application/${uid}/revision-remark`;

Api.APPLICATION_PRODUCTS_REVISION_REMARK = (uid) =>
  `/api-dev/Application/products/${uid}/revision-remark`;

Api.APPLICATION_REVISION_STATUS = (uid) =>
  `/api-dev/Application/${uid}/revision-status`;

Api.APPLICATION_PRODUCT_REVISION_STATUS = (uid) =>
  `/api-dev/Application/products/${uid}/revision-status`;

Api.APPLICATION_PRODUCT_VERIFY_OTP = (productUID) =>
  `/api-dev/Application/products/${productUID}/finish/verify-otp`;

Api.APPLICATION_SUBMIT = (uid) =>
  `/api-dev/Application/${uid}/status`;

Api.ARTICLES = (limit = 9, page = 1) =>
  `/api-dev/articles?isPublished=1&limit=${limit}&page=${page}`;

Api.ARTICLE = (slug) => `/api-dev/articles/${slug}`;

Api.BANK = (limit = 9, page = 1) => `/api-dev/banks?limit=${limit}&page=${page}`;

// Api.BANK_PRODUCTS = () => `/api-dev/banks/products/pagination`;
Api.BANK_PRODUCTS = () => `/api-dev/banks/v2/products/pagination`;

Api.BANNER = () => `/api-dev/Banner`;

Api.BANNER_DETAIL = (uid) => `/api-dev/Banner/${uid}`;

Api.BANNER_LOG = () => `/api-dev/Banner/entryLog`;

Api.CALCULATOR = () =>
  `/api-dev/calculator/calculate-kpr`;

Api.CALCULATOR_KPR_PUBLIC = () =>
  `/api-dev/calculator/public/calculate-kpr`;

Api.CALCULATOR_REFINANCE_PUBLIC = () =>
  `/api-dev/calculator/public/calculate-refinance`;

Api.CALCULATOR_REFINANCE = () =>
  `/api-dev/calculator/calculate-kpr-refinance`;

Api.CALCULATOR_TAKEOVER = () =>
  `/api-dev/calculator/calculate-kpr-takeover`;

Api.CANCEL_PREDRAFTS = (uid) =>
  `/api-dev/predrafts/${uid}/cancel`;

Api.CART = () =>
  `/api-dev/carts`;

Api.CART_REFINANCE = () =>
  `/api-dev/carts/refinance`;

Api.CART_SECONDARY = () =>
  `/api-dev/carts/secondary`;

Api.CART_TAKEOVER = () =>
  `/api-dev/carts/takeover`;

Api.CART_TAKEOVER_REFINANCE = () =>
  `/api-dev/carts/takeover-refinance`;

Api.CHECK_VERSION = () =>
  `/api-dev/version/check`;

Api.DETAIL_CART = (uid) =>
  `/api-dev/carts/${uid}`;

Api.UPDATE_CART = (uid) =>
  `/api-dev/carts/${uid}`;

Api.CONTACT_US = () => `/api-dev/contactus`;

Api.DELETE_CART = (uid) =>
  `/api-dev/carts/${uid}`;

Api.FAQS = () =>
  `/api-dev/faqs?limit=1000&page=1&sortBy=GroupName%3Aasc%2CDisplayOrder%3Aasc`;

Api.FAQ = () =>
  `/api-dev/faqs`;

Api.CONTENTS = (type) =>
  `/api-dev/contents?type=${type}&limit=10&page=1&sort=DisplayOrder%3Aasc`;

Api.TERMS = (GroupName) =>
  `/api-dev/contents?type=pages&groupNames=${GroupName}&limit=10&page=1`;

Api.PRIVACY = (GroupName) =>
  `/api-dev/contents?type=pages&groupNames=${GroupName}&limit=10&page=1`;

Api.NEWSARTICLE = (type) =>
  `/api-dev/contents?type=${type}&limit=100&page=1`;

Api.MEMBER_BANK = () =>
  `/api-dev/member/bank`;

Api.MEMBER_IDEAL_SCORE = (uid) =>
  `/api-dev/member-preq-app${uid ? `/${uid}` : ''}`;

Api.MEMBER_IDEAL_SCORE_DOCUMENTS = () =>
  `/api-dev/member-preq-app/documents`;

Api.MEMBER_IDEAL_SCORE_FILE = () =>
  `/api-dev/member-preq-app/prequalification-file`;

Api.MEMBER_IDEAL_SCORE_FILE_ADD = (uid) =>
  `/api-dev/member-preq-app/${uid}/files`;

Api.MEMBER_IDEAL_SCORE_FILE_REMOVE = (uid) =>
  `/api-dev/member-preq-app/files/${uid}`;

Api.MEMBER_IDEAL_SCORE_INPROGRESS = (uid) =>
  `/api-dev/member-preq-app/${uid}/set-inprogress`;

Api.MEMBER_IDEAL_SCORE_SUBMIT = (uid) =>
  `/api-dev/member-preq-app/${uid}/submit`;

Api.MEMBER_UNSUBSCRIBE = () =>
  `/api-dev/member/deactivate-subscription`;

Api.REFRESH_TOKEN = () =>
  `/api-dev/member/refresh-token`;

Api.LOGIN = () =>
  `/api-dev/member/login`;

Api.LOGOUT = () =>
  `/api-dev/member/logout`;

Api.LOGIN_GOOGLE = () =>
  `/api-dev/member/login/google`;

Api.LOGIN_APPLE = () =>
  `/api-dev/member/login/apple`;

Api.REGISTER = () =>
  `/api-dev/member/register`;

Api.REGISTER_GOOGLE = () =>
  `/api-dev/member/register/google`;

Api.REGISTER_APPLE = () =>
  `/api-dev/member/register/apple`;

Api.REMOVE_PRODUCT = (uid) =>
  `/api-dev/carts/${uid}/products`;

Api.OTP_VALIDATION = () =>
  `/api-dev/member/register/otp`;

Api.ADD_ACCOUNT_DETAIL = () =>
  `/api-dev/member/register/account`;

Api.FORGOT_PASSWORD = () =>
  `/api-dev/member/forgot-password`;

Api.FORGOT_PASSWORD_OTP = () =>
  `/api-dev/member/forgot-password/otp`;

Api.PRODUCT_CATEGORY_INFO = () =>
  `/api-dev/products/info-category`;

Api.RESET_PASSWORD = () =>
  `/api-dev/member/forgot-password/update`;

Api.RESEND_EMAIL_VERIFICATION = () =>
  `/api-dev/member/resend-email-verification`;

Api.EMAIL_ACTIVATE = (uid) =>
  `/api-dev/member/email-validation/${uid}`;

Api.CHECK_PREDRAFT_COMPLETED = () =>
  `/api-dev/predrafts/completed`;

Api.CHECK_PREDRAFT_PROCESSING = () =>
  `/api-dev/predrafts/in-processing`;

Api.CHECK_USER_REGISTERED = () =>
  `/api-dev/member/email-exist`;

Api.DELETE_ACCOUNT = () =>
  `/api-dev/member/delete/otp`;

Api.USER_PROFILE = () =>
  `/api-dev/member/profile`;

Api.USER_PROFILE_PHOTO = () =>
  `/api-dev/member/profile/photo`;

Api.CHANGE_PROFILE_EMAIL = () =>
  `/api-dev/member/profile/email`;

Api.CHANGE_PROFILE_PASSWORD = () =>
  `/api-dev/member/profile/password/update`;

Api.CHANGE_PROFILE_PHONE = () =>
  `/api-dev/member/profile/phone`;

Api.CHANGE_PROFILE_NEWSLETTER = () =>
  `/api-dev/member/newsletter-subscription`;

Api.MASTER_LIST = () =>
  `/api-dev/masterlist`;

Api.DISTRICTS = () =>
  `/api-dev/areas/districts`;

Api.DISTRICTS_PAGINATION = () =>
  `/api-dev/areas/districts-pagination`;

Api.PROVINCES = () =>
  `/api-dev/areas/provices`;

Api.REGENCIES = () =>
  `/api-dev/areas/regencies`;

Api.DEVELOPERS = () =>
  `/api-dev/developers`;

Api.DEVELOPERS_DETAIL = (id) =>
  `/api-dev/developers/${id}`;

Api.PROPERTIES = () =>
  `/api-dev/properties`;

Api.PROPERTIES_LANDING_PAGE = () =>
  `/api-dev/properties/landing-page`;

Api.PROPERTY_DETAIL = (uid) =>
  `/api-dev/properties/${uid}`;

Api.PROPERTIES_UNITS = () =>
  `/api-dev/properties/units`;

Api.PROPERTY_UNITS = (propertyId) =>
  `/api-dev/properties/${propertyId}/units`;

Api.PROPERTY_UNIT_DETAIL = (unitUid) =>
  `/api-dev/properties/units/${unitUid}`;

Api.PRODUCT_RECOMMENDATIONS = (unitId) =>
  `/api-dev/properties/${unitId}/products/pagination`;

Api.PRODUCT_TAKEOVER_RECOMMENDATIONS = () =>
  `/api-dev/takeover/products/pagination`;

Api.PRODUCT_TAKEOVER_REFINANCE_RECOMMENDATIONS = () =>
  `/api-dev/takeover/refinance/products/pagination`;

Api.PRODUCT_EMAIL_DETAIL = () =>
  `/api-dev/products/send-detail-email`;

Api.SUBMIT_CART = (uid) =>
  `/api-dev/carts/${uid}/submit`;

Api.DocumentTypes = () =>
  `/api-dev/documenttypes`;

Api.PREDRAFTS = () =>
  `/api-dev/predrafts`;

Api.PREDRAFTS_CART = (uid) =>
  `/api-dev/predrafts/${uid}/cart`;

Api.PREDRAFTS_DETAIL = (uid) =>
  `/api-dev/predrafts/${uid}`;

Api.PREDRAFTS_DETAIL_MINIFIED = (uid) =>
  `/api-dev/predrafts/${uid}/short-detail`;

Api.PREDRAFTS_FILE_ADD = (uid) =>
  `/api-dev/predrafts/${uid}/files`;

Api.PREDRAFTS_FILE_REMOVE = (predraftUid, fileUid) =>
  `/api-dev/predrafts/${predraftUid}/files/${fileUid}`;

Api.PREDRAFTS_UPDATE_NIK = (uid) =>
  `/api-dev/predrafts/${uid}/update-nik`;

Api.PREDRAFTS_SUBMIT = (uid) =>
  `/api-dev/predrafts/${uid}/submit`;

Api.PREDRAFTS_SUBMIT_APP = (uid) =>
  `/api-dev/predrafts/${uid}/submit-application`;

Api.PRODUCTS_TENOR = () =>
  `/api-dev/products/tenor`;

Api.REFERRAL_CODE_UPDATE = () =>
  `/api-dev/member/referral-code`;

Api.REFERRAL_MY_REWARD = () =>
  `/api-dev/member/my-rewards`;

Api.REFERRAL_REDEEM = () =>
  `/api-dev/member/redeem`;

Api.REFERRAL_REDEEM_DETAIL = (uid) =>
  `/api-dev/member/redeem/${uid}`;

Api.REFERRAL_REDEEM_DOCUMENTS = () =>
  `/api-dev/member/redeem/documents`;

Api.REFERRAL_REDEEM_FILE = () =>
  `/api-dev/member/redeem/file`;

Api.REFERRAL_REFERRER_DETAIL = (uid) =>
  `/api-dev/member/referrers/tracking/${uid}`;

Api.REF_REFERRAL_CODE_UPDATE = () =>
  `/api-dev/member/ref-referral-code`;

Api.REF_REFERRAL_CODE_VERIFY = () =>
  `/api-dev/member/ref-referral-code/verify`;

Api.TAKEOVER_TENOR = () =>
  `/api-dev/takeover/tenor`;

Api.UTM_TRACKING = () =>
  `/api-dev/common/utm`;

Api.Pay = {
  AccountValidate: () => `/api-pay/payments/account/validate`,
  AccountValidateCallback: () => `/api-pay/payments/account/validate/callback`,
  AddRecipient: () => `/api-pay/payments/recipient`,
  CancelTransaction: (uid) => `/api-pay/payments/transactions/${uid}/cancel`,
  CheckPaymentCard: () => `/api-pay/payments/check-paycard-bin`,
  DetailAccount: () => `/api-pay/payments/recipients/account`,
  DetailActivity: (uid) => `/api-pay/payments/activities/${uid}`,
  DetailTransaction: (uid) => `/api-pay/payments/transactions/${uid}`,
  FormCreditCard: (uid) => `/api-pay/payments/pay-methods${uid ? `/${uid}` : ''}`,
  FormRecipient: (uid) => `/api-pay/payments/recipients${uid ? `/${uid}` : ''}`,
  FormTransaction: (uid) => `/api-pay/payments/transactions${uid ? `/${uid}` : ''}`,
  GenerateToken: () => `/api-pay/payments/token/generate`,
  ListActivity: () => `/api-pay/payments/activities`,
  ListChannel: () => `/api-pay/payments/payout-channels`,
  ListCreditCard: () => `/api-pay/payments/pay-methods`,
  ListPaymentMethod: () => `/api-pay/payments/available-pay-methods`,
  ListRecipient: () => `/api-pay/payments/recipients`,
  MidtransCharge: () => `/api-pay/payments/midtrans/creditcard/charge`,
  TransactionLog: (uid) => `/api-pay/payments/transactions/${uid}/log`,
  UpdatePaymentCard: () => `/api-pay/payments/transactions/paycardbin`,
  UpdateRecipientFavorite: (uid) => `/api-pay/payments/recipients/${uid}/favorite`,
  UpdateTransactionMethod: () => `/api-pay/payments/transactions/payment-method`,
  ValidateToken: () => `/api-pay/payments/token/validate`,
  XenditCharge: () => `/api-pay/payments/xendit/creditcard/charge`,
}

Api.Pay.V2 = {
  XenditCharge: () => `/api-pay/v2/payments/xendit/creditcard/charge`,
}

Api.V2 = {
  AcceptMemberTerms: (uid, accepted, from) => `/api-dev/member/v2/accept-toc/${uid}?${from ? `from=${from}&` : ''}accepted=${accepted}`,
  AddCartTakeover: () => `/api-dev/carts/v2/takeover`,
  DetailApplication: (uid) => `/api-dev/applications/v2/${uid}`,
  FormAgent: () => `/api-dev/account/v2/agent`,
  FormAgentOTP: () => `/api-dev/account/v2/agent/verify-otp`,
  ListApplicationFiles: (uid) => `/api-dev/applications/v2/${uid}/files`,
  ListApplicationProduct: (uid) => `/api-dev/applications/v2/${uid}/products`,
  ListBank: () => `/api-dev/banks/v2`,
  ListDeveloper: () => `/api-dev/developers/v2`,
  ListTakeoverProduct: () => `/api-dev/takeover/v2/products/pagination`,
  ListTakeoverTenor: () => `/api-dev/takeover/v2/tenor`,
  ListTakeoverRefinanceTenor: () => `/api-dev/takeover/v2/refinance/tenor`,
  MemberChangePassword: () => `/api-dev/member/v2/profile/password/update`,
  MemberChangePhone: () => `/api-dev/member/v2/profile/phone`,
  MemberForgotPassword: () => `/api-dev/member/v2/forgot-password/update`,
  MemberLogin: () => `/api-dev/member/v2/login`,
  MemberRegister: () => `/api-dev/member/v2/register/account`,
  MemberVerifyCheckPhone: (uid) => `/api-dev/member/v2/verify/${uid}/phone`,
  MemberVerifySendOTP: (uid) => `/api-dev/member/v2/verify/${uid}/otp`,
  MemberVerifySubmit: (uid) => `/api-dev/member/v2/verify/${uid}/password`,
  MemberVerifyVerifyOTP: (uid) => `/api-dev/member/v2/verify/${uid}/otp/verify`,
  PredraftInProcessing: () => `/api-dev/v2/predrafts/in-processing`,
  UpdateIdealAssist: (uid) => `/api-dev/applications/v2/${uid}/ideal-assist`,
  VerifyRecaptcha: () => `/api-dev/v2/Google/recaptcha/verify`,
}

Api.V3 = {
  AddCartTakeover: () => `/api-dev/carts/v3/takeover`,
  ListBankProducts: () => `/api-dev/banks/v3/products/pagination`,
  ListBankRecommendation: () => `/api-dev/banks/v3/products/recommended`,
  ListTakeoverProduct: () => `/api-dev/takeover/v3/products/pagination`,
  ListTakeoverRecommendation: () => `/api-dev/takeover/v3/products/recommended`,
  SubmitCart: (uid) => `/api-dev/carts/v3/${uid}/submit`,
}

Api.V4 = {
  ListTakeoverProduct: () => `/api-dev/takeover/v4/products/pagination`,
}

export default Api;
